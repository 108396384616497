import React from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

// captcha 2 site key + secret
// 6LesN_ISAAAAACvGdInQ4F1v3K48YzYlHMdmiTY8
// 6LesN_ISAAAAAEZ8_xMFXEyrhYs-wuEum3lgoQkT

const ContatoForm = () => {
  const captcha = React.useRef(null);
  const [captchaToken, setCaptchaToken] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isActive, setActive] = React.useState(false);

  const onSubmit = async (data) => {
    if (captchaToken !== "") {
      setActive(!isActive);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", "enviar_email.php", true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.status === 200) {
          //console.error('Status:', xhr.status);
          //console.log(xhr.responseText);
          reset();
          captcha.current.reset();
          setCaptchaToken("");
          setActive(isActive);
        } else {
          //console.error('Request failed. Status:', xhr.status);
          captcha.current.reset();
          setCaptchaToken("");
          setActive(isActive);
        }
      };
      xhr.onerror = () => {
        //console.error('Request failed. Status:', xhr.status);
        captcha.current.reset();
        setCaptchaToken("");
        setActive(isActive);
      };

      const name = data.name;
      const email = data.email;
      const message = data.message;
      const dataFinal = { name, email, message, captchaToken };

      const requestBody = JSON.stringify(dataFinal);

      xhr.send(requestBody);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={isActive ? "ativo" : undefined}
    >
      <div className="row">
        <fieldset className="col-12 col-md-6">
          <input
            placeholder="Nome"
            id="name"
            type="text"
            {...register("name", { required: true, minLength: 2 })}
            required
            disabled={isActive && "disabled"}
          />
          {errors.name && null}
          <label htmlFor="name">
            <i className="aa_icons user"></i>
          </label>
        </fieldset>
        <fieldset className="col-12 col-md-6">
          <input
            placeholder="E-mail"
            id="email"
            type="email"
            {...register("email", {
              required: true,
              pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
            })}
            required
            disabled={isActive ? "disabled" : null}
          />
          {errors.email && null}
          <label htmlFor="email">
            <i className="far fa-envelope"></i>
          </label>
        </fieldset>
      </div>
      <div className="row">
        <fieldset className="col-12">
          <textarea
            placeholder="Mensagem"
            id="message"
            {...register("message", { required: true, minLength: 2 })}
            required
            disabled={isActive && "disabled"}
          />
          {errors.message && null}
          <label htmlFor="message">
            <i className="far fa-comment"></i>
          </label>
        </fieldset>
      </div>
      <div className="row">
        <div className="col-12 recaptcha">
          <ReCAPTCHA
            //size="compact"
            //theme="dark"
            ref={captcha}
            hl="pt-BR"
            sitekey="6LesN_ISAAAAACvGdInQ4F1v3K48YzYlHMdmiTY8"
            onChange={(token) => {
              setCaptchaToken(token);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="submit"
            disabled={isActive && "disabled"}
            //onClick={toggleClass}
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContatoForm;
