const habilidadesLista = [
  {
    titulo: "UX/UI",
    tecnologia: [
      { titulo: "Xd", icone: "aa_icons xd" },
      { titulo: "Photoshop", icone: "aa_icons ps" },
      { titulo: "Illustrator", icone: "aa_icons ai" },
      { titulo: "Figma", icone: "aa_icons figma" },
      { titulo: "Sketch", icone: "linearicons-diamond3" },
    ],
  },

  {
    titulo: "Linguagens Web",
    tecnologia: [
      { titulo: "HTML5", icone: "fab fa-html5" },
      { titulo: "CSS3", icone: "fab fa-css3-alt" },
      { titulo: "Javascript", icone: "fab fa-js" },
      { titulo: "PHP", icone: "fab fa-php" },
      { titulo: "SQL", icone: "linearicons-database" },
    ],
  },

  {
    titulo: "Frameworks e Preprocessadores",
    tecnologia: [
      //{ titulo: 'Node.js', icone: 'fab fa-node-js' },
      { titulo: "React", icone: "fab fa-react" },
      { titulo: "npm", icone: "fab fa-npm" },
      { titulo: "Sass", icone: "fab fa-sass" },
      { titulo: "Gulp", icone: "fab fa-gulp" },
    ],
  },

  {
    titulo: "Gerenciadores de Conteúdo",
    tecnologia: [
      { titulo: "Wordpress", icone: "fab fa-wordpress-simple" },
      { titulo: "Joomla", icone: "fab fa-joomla" },
      { titulo: "Drupal", icone: "fab fa-drupal" },
    ],
  },

  {
    titulo: "Ferramentas e Sistemas",
    tecnologia: [
      { titulo: "Git", icone: "aa_icons git" },
      { titulo: "TortoiseSVN", icone: "aa_icons tortoise" },
      { titulo: "VS Code", icone: "aa_icons code" },
      { titulo: "Sublime", icone: "aa_icons sublime" },
      { titulo: "Mac OS", icone: "aa_icons macos" },
      { titulo: "Linux", icone: "fab fa-linux" },
      //{ titulo: 'cPanel', icone: 'fab fa-cpanel' },
    ],
  },
];

export default function Habilidades() {
  const listarHabilidades = habilidadesLista.map((experiencia, i) => (
    <article key={i}>
      <div className="row">
        <div className="col-12">
          <h4>{experiencia.titulo}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="icones">
            {experiencia.tecnologia &&
              experiencia.tecnologia.map((tecnologias, i) => (
                <li key={i}>
                  <i className={tecnologias.icone}></i>
                  {tecnologias.titulo}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </article>
  ));

  return (
    <section id="habilidades">
      <h2>Habilidades</h2>
      {listarHabilidades}
    </section>
  );
}
