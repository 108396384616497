import React from "react";
import ReactDOM from "react-dom/client";

import "./bootstrap.grid.min.css";
import "./fontawesome.min.css";
import "./linearicons.css";
import "./style.css";

import Header from "./header.js";
import Footer from "./footer.js";
import MouseBrilho from "./mouse.js";
import SobreMim from "./sobre-mim.js";
import Habilidades from "./habilidades.js";
// import Experiencia from "./experiencia.js";
import Projetos from "./projetos.js";
import Contato from "./contato.js";
import Creditos from "./creditos.js";

function Portfolio() {
  return (
    <div className="container-fluid">
      <div className="container">
        <MouseBrilho />
        <Header />
        <main>
          <SobreMim />
          <Habilidades />
          {/* <Experiencia /> */}
          <Projetos />
          <Contato />
          <Creditos />
        </main>
        <Footer />
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Portfolio />
  </React.StrictMode>
);
