export default function SobreMim() {
  return (
    <section id="sobre-mim">
      <p>
        Sou um <em>desenvolvedor web</em> com mais de 15 anos de experiência, 4
        deles tendo atuado na{" "}
        <a
          href="https://catho.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Catho Online
        </a>
        , o maior portal de empregos do Brasil.
      </p>
      <p>
        Durante minha carreira, adquiri habilidades tanto em <em>UX/UI</em> que
        fazem parte do design e experiência do usuário como no desenvolvimento{" "}
        <em>front-end</em> e <em>back-end</em>, que me possibilitam em criar
        soluções completas e eficientes.
      </p>
      <p>
        Como um desenvolvedor <em>full-stack</em> experiente, sou capaz de
        entender e colaborar em todas as fases de um projeto web, desde a
        concepção e planejamento até o desenvolvimento, implementação e a
        publicação.
      </p>
      <p>
        Nos últimos anos tenho atuado como <em>freelancer</em> e desenvolvido
        projetos para pequenas empresas além de oferecer serviços de design na
        plataforma{" "}
        <a
          href="https://99designs.com.br/profiles/arthuralbuquerque/about"
          target="_blank"
          rel="noopener noreferrer"
        >
          99designs
        </a>{" "}
        sendo reconhecido como <em>top level</em> designer da comunidade.
      </p>
    </section>
  );
}
