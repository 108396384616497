import { projetosLista } from "./projetos-lista.js";

const listarProjetos = projetosLista.map((projeto, index) => (
  <article key={index} className="row item">
    <div className="col-4 col-xl-6">
      <a href={projeto.url} target="_blank" rel="noopener noreferrer">
        <img src={projeto.imagem} alt={projeto.titulo} />
        <span>Ver Projeto</span>
      </a>
    </div>
    <div className="col-8 col-xl-6">
      <h3>{projeto.titulo}</h3>
      <h4>{projeto.ano}</h4>
      <p>{projeto.descricao}</p>
      <ul className="selos">
        {projeto.tecnologia &&
          projeto.tecnologia.map((tecnologias, index) => (
            <li key={index}>{tecnologias.titulo}</li>
          ))}
      </ul>
    </div>
  </article>
));

export default function Projetos() {
  return (
    <section id="projetos">
      <h2>Projetos</h2>
      {listarProjetos}
    </section>
  );
}
