import React from "react";

export default function MouseBrilho() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const useMousePosition = () => {
    const [mousePosition, setMousePosition] = React.useState({
      top: null,
      left: null,
    });
    React.useEffect(() => {
      const updateMousePosition = (ev) => {
        setMousePosition({ left: ev.clientX - 400, top: ev.clientY - 400 });
      };
      window.addEventListener("mousemove", updateMousePosition);
      return () => {
        window.removeEventListener("mousemove", updateMousePosition);
      };
    }, []);
    return mousePosition;
  };
  const mousePosition = useMousePosition();

  return (
    <div
      className="mouse-brilho"
      style={width < 768 ? null : { position: "fixed", ...mousePosition }}
    ></div>
  );
}
