import React from "react";
import { Link } from "react-scroll";
import aa_profile from "./img/aa_profile.jpg";

export default function Header() {
  const minWidth = 768;

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const [isActive, setActive] = React.useState("false");
  const handleToggle = () => {
    if (width < minWidth) {
      setActive(!isActive);
      if (isActive) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
    }
  };

  function MenuLink(props) {
    return (
      <Link
        onClick={handleToggle}
        activeClass="ativo"
        spy={true}
        hashSpy={true}
        spyThrottle={0}
        offset={width < minWidth ? -80 : 0}
        isDynamic={true}
        delay={200}
        smooth={true}
        duration={500}
        to={props.url}
      >
        {props.titulo}
      </Link>
    );
  }

  return (
    <header className={isActive ? null : "ativo"}>
      <img src={aa_profile} alt="Arthur Albuquerque" />
      <h1>
        <a href="./">Arthur Albuquerque</a>
      </h1>
      <h3>Web Developer</h3>
      <p>
        Sou desenvolvedor web a mais de 15 anos. Faço websites responsivos e
        criativos.
      </p>
      <nav>
        <ul>
          <li>
            <MenuLink url="sobre-mim" titulo="Sobre mim" />
          </li>
          <li>
            <MenuLink url="habilidades" titulo="Habilidades" />
          </li>
          {/* <li>
            <MenuLink url="experiencia" titulo="Experiência" />
          </li> */}
          <li>
            <MenuLink url="projetos" titulo="Projetos" />
          </li>
          <li>
            <MenuLink url="contato" titulo="Contato" />
          </li>
        </ul>
      </nav>
      <button className="menu" onClick={handleToggle}>
        <span></span>
      </button>
      <div className="sobreposicao" onClick={handleToggle}></div>
    </header>
  );
}
