import ContatoForm from "./contato-form.js";

export default function Contato() {
  return (
    <section id="contato">
      <h2>Contato</h2>

      <h3>Vamos trabalhar juntos?</h3>
      <h4>Mande uma mensagem...</h4>

      <ContatoForm />

      <ul>
        <li>
          <a
            href="mailto:contato@arthuralbuquerque.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-envelope"></i>
            <span>contato@arthuralbuquerque.com.br</span>
          </a>
        </li>
        <li>
          <a
            href=" https://wa.me/5511958025289"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
            <span>+55 11 95802-5289</span>
          </a>
        </li>
        {/*
        <li><a href=" https://linkedin.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i><span>/arthur.albuquerque</span></a></li>
        <li><a href=" https://github.com/arthurbass" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i><span>/arthurbass</span></a></li>
        */}
      </ul>
    </section>
  );
}
