export default function Footer() {
  return (
    <footer>
      <ul>
        <li>
          <a
            href="mailto:arthur.albuquerque@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-envelope"></i>
            <span>E-mail</span>
          </a>
        </li>
        <li>
          <a
            href=" https://wa.me/5511958025289"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
            <span>Whatsapp</span>
          </a>
        </li>
        {/* <li>
          <a
            href=" https://www.linkedin.com/in/arthur-sm-albuquerque/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
            <span>LinkedIn</span>
          </a>
        </li> */}
        {/*
        <li><a href=" https://github.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i><span>Github</span></a></li>
        */}
      </ul>
      {new Date().getFullYear()} &copy;{" "}
      <a href="./">arthuralbuquerque.com.br</a>
    </footer>
  );
}
