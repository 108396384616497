export const projetosLista = [
  {
    titulo: "Loja Toc&Toc",
    ano: "2024",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-lojatoctoc.jpg"),
    imagem_full: [
      {
        src: require("./img/arthuralbuquerque.com.br-projetos-lojatoctoc-full.jpg"),
      },
    ],
    url: "https://lojatoctoc.com",
    descricao:
      "Criação de tema Wordpress para a Loja Toc&Toc. O projeto envolve um e-commerce feito com WooCommerce usando o sistema de pagamento Mercado Pago.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
      { titulo: "WooCommerce" },
      { titulo: "Mercado Pago" },
    ],
  },

  /*
  { titulo: 'Sainte-Marie Lyon', 
    ano: '2023', 
    imagem: require('./img/arthuralbuquerque.com.br-projetos-sml.jpg'), 
    imagem_full: [
      { src: require('./img/arthuralbuquerque.com.br-projetos-sml-full.jpg') }
    ],
    url: 'https://dons.sainte-marie-lyon.fr', 
    descricao: 'Criação de tema Wordpress com sistema de doação para escola francesa. O projeto também envolve um sistema de doações onde o usuário pode doar a quantia que desejar usando o sistema de pagamento Paypal e Systempay.',
    tecnologia: [ 
      { titulo: 'Front-end' }, 
      { titulo: 'Back-end' }, 
      { titulo: 'Wordpress' }, 
      { titulo: 'WooCommerce' }, 
      { titulo: 'Paypal' }, 
      { titulo: 'Systempay' }
    ] 
  },
  */

  {
    titulo: "Instituto Krizek",
    ano: "2023",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-ik.jpg"),
    imagem_full: [
      { src: require("./img/arthuralbuquerque.com.br-projetos-ik-full.jpg") },
    ],
    url: "https://institutokrizek.com.br/",
    descricao:
      "Criação de tema Wordpress para o Instituto Krizek. O projeto também envolve a importação dos posts de um Blogger.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
    ],
  },

  /*
  { titulo: 'Recontar', 
    ano: '2022', 
    imagem: require('./img/arthuralbuquerque.com.br-projetos-recontar.jpg'),
    imagem_full: [
      { src: require('./img/arthuralbuquerque.com.br-projetos-recontar-full.jpg') }
    ], 
    url: 'https://recontar.net.br/', 
    descricao: 'Criação de tema Wordpress para a organização Recontar.',
    tecnologia: [ 
      { titulo: 'UX/UI' }, 
      { titulo: 'Front-end' }, 
      { titulo: 'Back-end' }, 
      { titulo: 'Wordpress' } 
    ] 
  },
  */

  {
    titulo: "Lilian Fraga",
    ano: "2022",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-lilianfraga.jpg"),
    imagem_full: [
      {
        src: require("./img/arthuralbuquerque.com.br-projetos-lilianfraga-full.jpg"),
      },
    ],
    url: "https://lilianfraga.com.br/",
    descricao:
      "Criação de tema Wordpress para a arquiteta Lilian Fraga. O projeto também envolve uma capação de e-mails onde o usuário se cadastra e recebe um eBook da profissional.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
    ],
  },

  {
    titulo: "Morning Birds",
    ano: "2021",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-mb.jpg"),
    imagem_full: [
      { src: require("./img/arthuralbuquerque.com.br-projetos-mb-full.jpg") },
    ],
    url: "https://morningbirdsmusic.com/",
    descricao:
      "Criação de tema Wordpress para a produtora Morning Birds. O projeto envolve um catálogo de projetos criados pela produtora e artistas agenciados.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
    ],
  },

  {
    titulo: "Skafandros Orquestra",
    ano: "2021",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-skafandros.jpg"),
    imagem_full: [
      {
        src: require("./img/arthuralbuquerque.com.br-projetos-skafandros-full.jpg"),
      },
    ],
    url: "https://skafandros.com.br/",
    descricao:
      "Criação de tema Wordpress para a banda Skafandros Orquestra. O projeto também envolve um crawler que adiciona os posts da banda no Facebook diretamente na seção de novidades do site.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
      { titulo: "Facebook Crawler" },
    ],
  },

  {
    titulo: "Emuv Fit",
    ano: "2021",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-emuv.jpg"),
    imagem_full: [
      { src: require("./img/arthuralbuquerque.com.br-projetos-emuv-full.jpg") },
    ],
    url: "https://emuv.fit/",
    descricao:
      "Criação de tema Wordpress para a startup Emuv Fit. O projeto também envolve um sistema de agendamento de aulas a distância com sistema de pagamento Pagseguro.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
      { titulo: "WooCommerce" },
      { titulo: "Pagseguro" },
      { titulo: "Google Calendar" },
    ],
  },

  {
    titulo: "Saracura",
    ano: "2020",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-saracura.jpg"),
    imagem_full: [
      {
        src: require("./img/arthuralbuquerque.com.br-projetos-saracura-full.jpg"),
      },
    ],
    url: "https://gruposaracura.com.br/",
    descricao:
      "Criação de tema Wordpress para o grupo Saracura. O projeto também envolve um sistema de doações onde o usuário pode doar a quantia que desejar usando o sistema de pagamento Pagseguro.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
      { titulo: "Pagseguro" },
    ],
  },

  {
    titulo: "Nomade Orquestra",
    ano: "2020",
    imagem: require("./img/arthuralbuquerque.com.br-projetos-nomadeorquestra.jpg"),
    imagem_full: [
      {
        src: require("./img/arthuralbuquerque.com.br-projetos-nomadeorquestra-full.jpg"),
      },
    ],
    url: "https://nomadeorquestra.com.br/",
    descricao:
      "Criação de tema Wordpress para a banda Nomade Orquestra. O projeto também envolve um eCommerce com sistemas de pagamento Pagseguro e Paypal, além de integração com a plataforma Songkick para exibição da agenda da banda.",
    tecnologia: [
      { titulo: "UX/UI" },
      { titulo: "Front-end" },
      { titulo: "Back-end" },
      { titulo: "Wordpress" },
      { titulo: "WooCommerce" },
      { titulo: "Pagseguro" },
      { titulo: "Paypal" },
      { titulo: "Songkick" },
    ],
  },
];
